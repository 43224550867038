import React, { useEffect, useRef } from 'react';

//https://threejs.org/docs/index.html#manual/en/introduction/Loading-3D-models
//import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
          
import ModelIOUtil from '../utility/modelIOUtil';
import { Scene, HemisphereLight, Vector3, Quaternion, PerspectiveCamera, WebGLRenderer, sRGBEncoding, Color, Mesh, Light, Box3, SpotLight } from 'three';
import SceneUtil from '../utility/sceneUtil';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';

var DemoModelUrl = process.env.PUBLIC_URL + '/whole_pizza_highres_automated_norotationtranslate.glb'
var DemoModelMetadata = JSON.parse(`{"position":[2.1250915034050304,10.062161672171625,12.327872495386261],"quaternion":[0.93719847921982,0.00948099674787026,-0.06966757390991517,0.34163657648769136]}`);
var DemoInputOptions = {
  fov:17,
  toneMappingExposure:1.25,
  fitToCameraOffset:5,
  roateAngleDiv: 2.9,
  linearTextureEncoding:false
}

export interface Props {
  modelUrl?:string;
  width?:number;
  height?:number;
  modelFile?:File;
  meshAdjustmentModel?:any;
  cameraForMeshModel?:any;
  interactive?:boolean;
  disableZoom?:boolean;
  className?:string
}

  export default function ThreeDModelViewer(props:Props) {
    var animationFrameId:number|null;

    var modelUrl = props.modelUrl;
    var modelFile = props.modelFile;

    var meshAdjustmentModel = props.meshAdjustmentModel;
    var cameraForMeshModel = props.cameraForMeshModel;
    var isNotInteractive = props.interactive != null && props.interactive != undefined && props.interactive == false;
    var disableZoom = props.disableZoom != undefined && props.disableZoom == true;

    var controls:OrbitControls
    var scene:Scene;
    var renderer:WebGLRenderer;
    var camera:PerspectiveCamera;
    var light:Light; // SpotLight, DirectionalLight

    const containerRef = useRef(null);

    useEffect(() => {
      init();
      // cleanup webgl, otherwise "Too many active WebGL contexts. Oldest context will be lost"
      // https://threejs.org/docs/#manual/en/introduction/How-to-dispose-of-objects
      return () => {
        if(animationFrameId) {
          cancelAnimationFrame(animationFrameId)
        }
      };
    }, []); /* only fetch ONCE */

    // reuse cavas classname for parent
    return (
      <span ref={containerRef} className={props.className}/>
    )

    function animate() {    
      resizeCanvasToDisplaySize();

      animationFrameId = requestAnimationFrame( animate );
      
      // required if controls.enableDamping or controls.autoRotate are set to true
      controls.update();

      renderer.render( scene, camera );
    }

    function resizeCanvasToDisplaySize() {
      const canvas = renderer.domElement;
      // look up the size the canvas is being displayed
      const width = canvas.clientWidth;
      const height = canvas.clientHeight;
    
      // adjust displayBuffer size to match
      if (canvas.width !== width || canvas.height !== height) {
        // you must pass false here or three.js sadly fights the browser
        renderer.setSize(width, height, false);
        camera.aspect = width / height;
        camera.updateProjectionMatrix();
    
        // update any render target sizes here
        var sceneUtil = new SceneUtil();
        var mesh0 = getMesh0();
        if(mesh0 != null) {
          sceneUtil.fitCameraToObject(camera, [mesh0], controls);
        }
        //controls.update(); // needed?
      }
    }

    function checkSupport() {
    /*
      if (!(window.File && window.FileReader && window.FileList && window.Blob)) {
        console.error('The File APIs are not fully supported in this browser.');
      } else if (!WEBGL.isWebGLAvailable()) {
        console.error('WebGL is not supported in this browser.');
      }
      */
    }    

    async function init() {
      checkSupport();
      
      scene = new Scene();

      if(modelUrl == undefined && modelFile == undefined) {
        modelUrl = DemoModelUrl;
        meshAdjustmentModel = DemoModelMetadata;
        cameraForMeshModel = DemoInputOptions;
      }


      //camera = new PerspectiveCamera( 25, renderWidth / renderHeight, 0.1, 1000 );
      camera = new PerspectiveCamera( 25, 2, 0.1, 1000 ); // 1 or 2 aspect ratio?

      // https://stackoverflow.com/questions/14614252/how-to-fit-camera-to-object/14614736#14614736
      // https://codepen.io/andrewray/pen/obZWvK?editors=001

      renderer = new WebGLRenderer();
      //renderer.physicallyCorrectLights=true; // ?
      
      if(cameraForMeshModel.toneMappingExposure) {
        renderer.toneMappingExposure = cameraForMeshModel.toneMappingExposure;
      }

      // https://threejs.org/docs/#examples/en/loaders/GLTFLoader
      renderer.outputEncoding = sRGBEncoding;
      renderer.setPixelRatio( window.devicePixelRatio );


      // https://www.w3schools.com/jsref/met_element_setattribute.asp
      // apply style to remove border
      renderer.domElement.style.outline = "none";
      renderer.domElement.style.border = "none";

      renderer.domElement.className=props.className ?? ""

      var propWidth = props.width;
      var propHeight = props.height;
      if(propWidth != null && propHeight != null) {
        renderer.setSize( propWidth, propHeight );
      }

      controls = new OrbitControls( camera, renderer.domElement );
      controls.autoRotate=true
      controls.autoRotateSpeed=0.5
      controls.maxPolarAngle = Math.PI / cameraForMeshModel.roateAngleDiv;

      if(isNotInteractive) {
        controls.enabled = false;
      }
      if(disableZoom) {
        controls.enableZoom = false;
      }
  
      //renderer.toneMapping = ReinhardToneMapping;
      /*
      light = new DirectionalLight( 0xffffff );
      light.position.set( 0, 1, 0 );
      scene.add( light );
      */

      /*
      var spotLightHelper = new SpotLightHelper( light );
      scene.add( spotLightHelper );
      */

      /*
      light = new DirectionalLight(0xffffff, .5);
      light.position.set( 10, 10, 10 );
      */
      light = new HemisphereLight();
      scene.add( light );
      //var light = new THREE.AmbientLight( 0xffffff ); // soft white light
      //scene.add( light );
      
      scene.background = new Color( 0xffffff ); // white
      
      // scene properties all set, now add it to the dom
      var containerRefAny:any = containerRef; // workaround for ridiculous typescript fail
      containerRefAny.current.appendChild( renderer.domElement );
 
      // render scene 1 time so we don't flash black (no render)
      renderer.render( scene, camera );

      var modelIOUtil = new ModelIOUtil();

      if(modelUrl) {

        const {gltf, error} = await modelIOUtil.loadAndParseUrl(modelUrl);
  
        if(gltf) {
          onGltfLoaded(gltf);
        }
        else {
          console.log( "failed");
          console.error( error );
        }
      }
      else if(modelFile) {

        const {gltf, error} = await modelIOUtil.parseFile(modelFile);
  
        if(gltf) {
          onGltfLoaded(gltf);
        }
        else {
          console.log( "failed");
          console.error( error );
        }
      }
    }

    function getMesh0() : any {
      return scene.getObjectByName( "mesh0" );
    }

    function onGltfLoaded(gltf:GLTF) {
      //GLTF
      var mesh0 = new SceneUtil().findFirstMesh(gltf.scene); // Mesh https://threejs.org/docs/#api/en/objects/Mesh

      if(mesh0 == null) {
        return;
      }

      // https://github.com/mrdoob/three.js/blob/master/examples/webgl_helpers.html
      // var helper1 = new VertexNormalsHelper( mesh0, .01);
      // scene.add( helper1 );

      // var helper2 = new VertexTangentsHelper( mesh0, 5 );
      // scene.add( helper2 );

      if(meshAdjustmentModel) {
        mesh0.geometry.computeBoundingBox(); // Geometry https://threejs.org/docs/#api/en/core/Geometry
        //mesh0.geometry.center();

        var modelBakedPositionTransform = new Vector3(meshAdjustmentModel.position[0],meshAdjustmentModel.position[1],meshAdjustmentModel.position[2]);
        var modelBakedRotationTransform = new Quaternion(meshAdjustmentModel.quaternion[0],meshAdjustmentModel.quaternion[1],meshAdjustmentModel.quaternion[2], meshAdjustmentModel.quaternion[3]);
        mesh0.quaternion.copy(modelBakedRotationTransform);

        //mesh0.geometry.translate(modelBakedPositionTransform.x, modelBakedPositionTransform.y, modelBakedPositionTransform.z);
        mesh0.position.copy(modelBakedPositionTransform);
      }

      mesh0.name = 'mesh0';
      scene.add( mesh0 );

      var sceneUtil = new SceneUtil();
      fitCameraToObject(camera, [mesh0], controls);
      //light.target = mesh0;

      animate();
    }

    function fitCameraToObject( camera:PerspectiveCamera, objects:Mesh[], controls:OrbitControls) {

      const box = new Box3();
    
      var fitOffset = 1;
      if(objects.length > 1) {
        fitOffset = 1.2;
      }
      else if(camera.aspect > 1) { // width > height we can zoom in more
        fitOffset = .7;
      }
  
      // can do a for loop if multiple object
      for( const object of objects ) {
        box.expandByObject( object );
      }
  
      // first set controls target
      controls.target = box.getCenter(new Vector3());
      
      const size = box.getSize( new Vector3() );
      const center = box.getCenter( new Vector3() );
      
      const maxSize = Math.max( size.x, size.y, size.z );
      const fitHeightDistance = maxSize / ( 2 * Math.atan( Math.PI * camera.fov / 360 ) );
      const fitWidthDistance = fitHeightDistance / camera.aspect;
      const distance = fitOffset * Math.max( fitHeightDistance, fitWidthDistance );
      
      const direction = controls.target.clone()
        .sub( camera.position )
        .normalize()
        .multiplyScalar( distance );
    
      controls.maxDistance = distance * 10;
      controls.target.copy( center );
      
      camera.near = distance / 100;
      camera.far = distance * 100;
      camera.updateProjectionMatrix();
  
      camera.position.copy( controls.target ).sub(direction);
      }

  }