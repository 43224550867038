
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { Object3D, PerspectiveCamera, Mesh, Box3, Vector3 } from 'three';

export default class SceneUtil {
    
  constructor() {
  
  }

  findFirstMesh(root:Object3D) : Mesh|null {
    var mesh0 = null;
    root.traverse( function ( object:Object3D ) {
        if (object instanceof Mesh)
        {
          mesh0 = object;
        }
    } );

    return mesh0;
  }

  // adapted from https://github.com/mrdoob/three.js/pull/14526#issuecomment-497254491
    /*  
    Notes: I haven't tested the controls.maxDistance, or the camera.near and .far to see whether they are optimal. 
    The values adapted from @donmccurdy's code seem to work well though.
    Also, the fitOffset = 1.2 is a minimum value that works. Since the view is set to the bound bound, 
    when the rotation of the frustum doesn't fit closely to the box, objects close to the edge will be 
    clipped without this.
    */
    fitCameraToObject( camera:PerspectiveCamera, objects:Mesh[], controls:OrbitControls) {

    const box = new Box3();
  
    var fitOffset = 1;
    if(objects.length > 1) {
      fitOffset = 1.2;
    }
    else if(camera.aspect > 1) { // width > height we can zoom in more
      fitOffset = .7;
    }

    // can do a for loop if multiple object
    for( const object of objects ) {
      box.expandByObject( object );
    }

    // first set controls target
    controls.target = box.getCenter(new Vector3());
    
    const size = box.getSize( new Vector3() );
    const center = box.getCenter( new Vector3() );
    
    const maxSize = Math.max( size.x, size.y, size.z );
    const fitHeightDistance = maxSize / ( 2 * Math.atan( Math.PI * camera.fov / 360 ) );
    const fitWidthDistance = fitHeightDistance / camera.aspect;
    const distance = fitOffset * Math.max( fitHeightDistance, fitWidthDistance );
    
    const direction = controls.target.clone()
      .sub( camera.position )
      .normalize()
      .multiplyScalar( distance );
  
    controls.maxDistance = distance * 10;
    controls.target.copy( center );
    
    camera.near = distance / 100;
    camera.far = distance * 100;
    camera.updateProjectionMatrix();
  
    camera.position.copy( controls.target ).sub(direction);
    }
}