import React, { Component } from 'react';
import './recipelistitem.css';
import { RecipeResponseWrapper,AssetResponseWrapper} from '../network/responses/recipe-crud-webapp-dto';
import LazyLoad from 'react-lazyload';
import {Button, Paper, Container, Typography, Box, Grid} from '@mui/material';
import { createTheme, ThemeProvider} from '@mui/material/styles';
import {makeStyles} from '@mui/styles'
import useMediaQuery from '@mui/material/useMediaQuery';

import CoverModelThreeDModelViewer from './coverModelThreeDModelViewer'
//const CoverModelThreeDModelViewer = React.lazy(() => import('./coverModelThreeDModelViewer'));

//https://material-ui.com/components/use-media-query/

function assetToRendition(asset?:AssetResponseWrapper|null) : AssetResponseWrapper | null {
    var renditionAsset:AssetResponseWrapper|null = null;
  
    if(asset != null) {
      asset.renditions.forEach(function(nextRendition, index1, array1) {
        renditionAsset = nextRendition;
      });
    }
  
    return renditionAsset;
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#64ba69',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#ffd700',
      contrastText: '#fffde7',
    },
  }
});

const useStyles = makeStyles((theme: { spacing: (arg0: number) => any; }) => ({
  button: {
    margin:12,
  },
}));

interface RecipeListItemProps {
  recipe:RecipeResponseWrapper
  editRecipeHander:any
  deleteRecipeHander:any
  viewRecipeHander:any
  canEdit:boolean
}

interface RecipeListItemModel {
}


export function RecipeListItem(props:RecipeListItemProps) {
  const classes = useStyles();
  var isMobileScreenSize = useMediaQuery('(max-width: 640px)');
  var lazyLoadImageHeight = isMobileScreenSize ? 336 : 480;
  var lazyLoadImageWidth = isMobileScreenSize ? 380 : 640;
  var lazyLoadImageOffset = isMobileScreenSize ? 100 : 200;

  const onViewRecipe = (event: React.MouseEvent) => {
    if(props.canEdit) {
      props.viewRecipeHander(props.recipe)
    }
  };

  const onEditRecipe = (event: React.MouseEvent) => {
    if(props.canEdit) {
      props.editRecipeHander(props.recipe)
    }
  };

  const onDeleteRecipe = (event: React.MouseEvent) => {
    if(props.canEdit) {
      props.deleteRecipeHander(props.recipe)
    }
  };  

  var visiblityValue = props.recipe.visibility

  var recipeName = props.recipe.objectName;
  var recipeLastUpdatedBy = "";
  
  if(props.canEdit) {

    var visiblityLabel = ""
    if(visiblityValue == "user_private") {
      visiblityLabel = "Link Only";
    }
    else if(visiblityValue == "world") {
        visiblityLabel = "Public"
    }

    recipeName += " [" + visiblityLabel + "]";
    recipeLastUpdatedBy = "updated by " + props.recipe.createdBy.username;
  }

  var recipe = props.recipe;
  let recipeViewUrl = "/recipe/" + recipe.chronicleId;
  //    cursor:pointer;

  var coverModelAsset = null;
  var coverModelImageAsset = null;
  if(recipe.coverAssets != null) {
    recipe.coverAssets.forEach(nextAsset => {
      if(nextAsset.assetType == "Model") {
        coverModelAsset = nextAsset;
      }
      else if(nextAsset.assetType == "Image") {
        coverModelImageAsset = nextAsset;
      }
    });
  }

  var coverView = null;
  var recipeModel:any = recipe.json;
  var coverImageRenditionAsset = assetToRendition(coverModelImageAsset);

  if(coverImageRenditionAsset != null && coverModelAsset != null && recipeModel.coverModel != null && recipeModel.coverModel.length > 0) {
    var targetPolygons = 1000;    
    var coverModel = recipeModel.coverModel[0];

    coverView = 
    <span className="recipe-list-item-container_modelandimage">
      <img src={coverImageRenditionAsset.url ?? ""} className="recipe-list-item-img" />
      <CoverModelThreeDModelViewer className="recipe-list-item-container_model" interactive={false} coverModel={coverModel} assets={recipe.coverAssets} targetPolygons={targetPolygons}/> 
    </span>
  }
  
  if(coverModelImageAsset != null && coverView == null) {
    var coverImageRenditionAsset = assetToRendition(coverModelImageAsset);
    if(coverImageRenditionAsset != null) {
      coverView = <img src={coverImageRenditionAsset.url ?? ""} className="recipe-list-item-img"></img>
    }
  }

  var editButton;
  var deleteButton;
  if(props.canEdit) {
    editButton = <Button variant="contained" onClick={onEditRecipe} style={{alignSelf:"flex-start"}}>edit</Button>
    deleteButton = <Button variant="contained" color='error' onClick={onDeleteRecipe} style={{alignSelf:"flex-start"}}>delete</Button>
  }
  else {
    editButton = ""
    deleteButton = ""
  }

  return (
      <li className="recipe-list-item" key={props.recipe.id} value={recipeName}>
          <ThemeProvider theme={theme}>
          {/* this centers */}
          <Grid container justifyContent = "center">
              <Paper elevation={3} style={{padding:8, width:"100%"}}>
                  <a href={recipeViewUrl}>
                    <LazyLoad height={lazyLoadImageHeight} offset={lazyLoadImageOffset}>
                      {coverView}
                    </LazyLoad>
                  </a>

                  <Box display="flex" justifyContent="space-around" marginTop={1}>
                      {editButton}
                      
                      <Box style={{alignSelf:"flex-end"}}>
                          <Typography variant={"h6"}>{recipeName}</Typography>
                          <Typography variant={"caption"}>{recipeLastUpdatedBy}</Typography>
                      </Box>

                      {deleteButton}
                      
                  </Box>
              </Paper>
              </Grid>
          </ThemeProvider>
      </li>
  );

}

export default RecipeListItem;

  