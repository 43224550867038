export class FileHash {
    file:File;
    sha256Hex:string;
  
    constructor(file: File, sha256Hex: string) {
      this.file = file;
      this.sha256Hex = sha256Hex;
    }
  }

export default class FileUtil {

    constructor() {
  
    }

    async readFileAsync(file:File, reader:FileReader) : Promise<string | ArrayBuffer | null> {
        return new Promise((resolve, reject) => {
          reader.onload = () => {
          reader.onload = null;
      
          resolve(reader.result);
          };
      
          reader.onerror = reject;
      
          reader.readAsArrayBuffer(file);
        })
    }

    async getFileInfo(file:File, reader:FileReader) : Promise<FileHash | null> {

        try {
         let contentBuffer = await new FileUtil().readFileAsync(file, reader);
     
         if (contentBuffer instanceof ArrayBuffer) {
           var array = new Uint8Array(contentBuffer);
     
           // Note in dev will not work on insecure url ie .local domain but will work on localhost
           // https://www.chromium.org/Home/chromium-security/prefer-secure-origins-for-powerful-new-features
           const digest = await crypto.subtle.digest("SHA-256", array);
           var sha256Hex = this.hexString(digest);
     
           return new FileHash(file, sha256Hex);
         }
       }
       catch(err) {
         console.log("failed to read file result: " + err)
       }
     
       return null;
     }

     hexString(buffer: any) : string {
        var s = '', h = '0123456789ABCDEF';
        (new Uint8Array(buffer)).forEach((v) => { s += h[v >> 4] + h[v & 15]; });
        return s.toLowerCase();
      }
}