import {
  TextToRecipeResponse,
  UploadAssetSignedRequestWrapper,
  S3AssetStoreServiceUploadAssetSignedRequest,
  AssetRenditionJobGroupResponse,
  OnUploadedAssetRequest,
  CreateOriginalModelAssetRequest,
  AssetParentType,
  DBObjectResponseWrapper,
  VersionedDBObjectResponseWrapper,
  RecipeResponseWrapper,
  RecipeResponse,
  RestResponse,
  AssetType,
  Visibility,
  MultiRecipeResponse,
  AssetResponseWrapper,
  AssetResponse,
  HashType
} from "./responses/recipe-crud-webapp-dto";
//import { string } from 'prop-types'
import Environment from '../utility/environment'

/*
async await tutorial
https://javascript.info/async-await
*/

interface StringMap { [s: string]: string; }

class RecipeRequests {

  constructor() {

  }

  jsonHeader() : StringMap {
    var headers:StringMap = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
    return headers;
  }

  async requestLogin(username:string, password:string, rememberme:boolean) : Promise<RestResponse> {

    var formData = new FormData();

    formData.append("username",username);
    formData.append("password",password);
    if(rememberme) {
      formData.append("remember-me","on");
    }

    var requestLoginFormUrl = this.getRecipeAPIURLWithPath("/login")

    var fetchRequest = new Request(requestLoginFormUrl.toString(), {
      method: 'POST',
      body: formData,
      redirect: 'manual',
      credentials: 'include'
    });

    const response = await fetch(fetchRequest);

    if(response.type === "opaqueredirect") {
      var successRestResponse:RestResponse = {success: true, errorCode : "", message : ""};
      return successRestResponse
    }
    else {
      return this.unknownFailureRestResponse();
    }

  }

  async requestAllPublicLatestRecipes() : Promise<MultiRecipeResponse | RestResponse> {
    var fetchRecipeJsonRequestUrl = this.getRecipeAPIURLWithPath("/recipe/list/public")

    var fetchRecipeJsonRequest = new Request(fetchRecipeJsonRequestUrl.toString(), {
      method: 'GET',
      redirect: 'manual',
      credentials: 'omit' // more cache re-use - public only query needs no credientials
    });

    const response = await fetch(fetchRecipeJsonRequest)

    var contentType = response.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
      var jsonResponse:MultiRecipeResponse = await response.json();

      if(jsonResponse != null && jsonResponse.recipeResponses != null) {
        jsonResponse.recipeResponses.forEach(nextRecipe => {
          nextRecipe.responseWrapper.json = JSON.parse(nextRecipe.responseWrapper.json);
        });
      }
      
      return jsonResponse
    } else {
      return this.unknownFailureRestResponse();
    }
  }

  async requestRecipesForSearch() : Promise<MultiRecipeResponse | RestResponse> {
    var fetchRecipeJsonRequestUrl = this.getRecipeAPIURLWithPath("/recipe/search/all")

    var fetchRecipeJsonRequest = new Request(fetchRecipeJsonRequestUrl.toString(), {
      method: 'GET',
      redirect: 'manual',
      credentials: 'omit' // more cache re-use - public only query needs no credientials
    });

    const response = await fetch(fetchRecipeJsonRequest)

    var contentType = response.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
      var jsonResponse:MultiRecipeResponse = await response.json();

      // no json returned to re-parse
      
      return jsonResponse
    } else {
      return this.unknownFailureRestResponse();
    }
  }

  async requestMyRecipes() : Promise<MultiRecipeResponse | RestResponse> {
    var fetchRecipeJsonRequestUrl = this.getRecipeAPIURLWithPath("/recipe/list")

    var fetchRecipeJsonRequest = new Request(fetchRecipeJsonRequestUrl.toString(), {
      method: 'GET',
      redirect: 'manual',
      credentials: 'include'
    });

    const response = await fetch(fetchRecipeJsonRequest)

    var contentType = response.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
      var jsonResponse:MultiRecipeResponse = await response.json();
      return jsonResponse
    } else {
      return this.unknownFailureRestResponse();
    }
  }

  async requestRecipeByChronId(recipeChronId: string) : Promise<RecipeResponse|RestResponse> {

    var fetchRecipeJsonRequestUrl = this.getRecipeAPIURLWithPath("/recipe/"+recipeChronId) //baseURL + 'recipe.json'

    var fetchRecipeJsonRequest = new Request(fetchRecipeJsonRequestUrl.toString(), {
      method: 'GET',
      redirect: 'manual',
      credentials: 'include'
    });

    const response = await fetch(fetchRecipeJsonRequest)

    var contentType = response.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
      var jsonResponse:RecipeResponse = await response.json();

      if(jsonResponse.success) {
          jsonResponse.responseWrapper.json = JSON.parse(jsonResponse.responseWrapper.json);
      }

      return jsonResponse
    } else {
      return this.unknownFailureRestResponse();
    }
  }

  contentTypeToAssetType(contentType:string) : AssetType | null{
    var contentTypeLowercase = contentType.toLowerCase();

    var assetType:AssetType|null = null;

    if(contentTypeLowercase === "image/jpeg") {
      assetType = "Image"
    }
    else if (contentTypeLowercase === "video/quicktime" || contentTypeLowercase === "video/mp4") {
      assetType = "Video"
    }
    else if(contentTypeLowercase === "model/gltf-binary" || contentTypeLowercase === "model/vnd.usdz+zip") {
      assetType = "Model"
    }
    return assetType;
  }

  async postAssetContent(file:File, contentType:string, signedRequestMetadata:S3AssetStoreServiceUploadAssetSignedRequest, parentSpecificObjectId:string, parentType:AssetParentType, sha256HashHex:string, optionalAssetParentId?:number) : Promise<AssetRenditionJobGroupResponse|RestResponse|null> {
    var assetType:AssetType|null = this.contentTypeToAssetType(contentType)
    if(assetType === null) {
      // TODO error message
      return null;
    }

    var originalsDropboxKey = signedRequestMetadata.formKeys.key;

    var formData = new FormData();

    var formKeyValuesToPost = signedRequestMetadata.formKeys;

    for(var key in formKeyValuesToPost) {
      var value  = formKeyValuesToPost[key];
      formData.append(key, value);
    }
    formData.append('file', file);

    var fetchRecipeJsonRequestUrl = signedRequestMetadata.url;

    // do not post to this aws url with credentials
    var fetchRequest = new Request(fetchRecipeJsonRequestUrl.toString(), {
      method: 'POST',
      body: formData
    });

    const response = await fetch(fetchRequest);

    if(response.ok === true) {

      var onUploadAsssetRequest:OnUploadedAssetRequest = 
      {
        parentId: Number(parentSpecificObjectId), 
        parentType: parentType,
        originalDropboxKey: originalsDropboxKey,
        hash: sha256HashHex,
        hashType: "SHA256",
        assetType: assetType,
        optionalParentAssetId:optionalAssetParentId
      }
      // console.log(signedRequestMetadata);
      // console.log(onUploadAsssetRequest)
      var requestOnAssetUploadResponse = await this.requestOnAssetUpload(onUploadAsssetRequest)
      
      if(this.isAssetRenditionJobGroupResponse(requestOnAssetUploadResponse)) {
        return requestOnAssetUploadResponse;
      }
      else if(this.isRestResponse(requestOnAssetUploadResponse)) {
        return requestOnAssetUploadResponse;
      }
      else {
        return null; // fail
      }
    }
    else {
      return null; // fail
      // todo UI error uploading content
    }
  }

  async updateRecipeVisibility(visiblity: Visibility, recipeId:string) : Promise<RestResponse> {
    var fetchRecipeJsonRequestUrl = this.getRecipeAPIURLWithPath("/recipe/visibility/"+recipeId) //baseURL + 'recipe.json'

    var fetchRecipeJsonRequest = new Request(fetchRecipeJsonRequestUrl.toString(), {
      method: 'POST',
      redirect: 'manual',
      credentials: 'include',
      body: visiblity
    });

    const response = await fetch(fetchRecipeJsonRequest)

    var contentType = response.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
      var jsonResponse:RecipeResponse = await response.json();
      return jsonResponse
    } else {
      return this.unknownFailureRestResponse();
    }
  }


  async updateRecipe(recipeText: string, recipeSpecificVersionId:string) : Promise<RecipeResponse|RestResponse> {
    var fetchRecipeJsonRequestUrl = this.getRecipeAPIURLWithPath("/recipe/"+recipeSpecificVersionId) //baseURL + 'recipe.json'

    var fetchRecipeJsonRequest = new Request(fetchRecipeJsonRequestUrl.toString(), {
      method: 'POST',
      redirect: 'manual',
      credentials: 'include',
      body: recipeText
    });

    const response = await fetch(fetchRecipeJsonRequest)

    var contentType = response.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
      var jsonResponse:RecipeResponse = await response.json();

      if(jsonResponse.success) {
          jsonResponse.responseWrapper.json = JSON.parse(jsonResponse.responseWrapper.json);
      }

      return jsonResponse
    } else {
      return this.unknownFailureRestResponse();
    }
  }

  async createRecipe(recipeText: string) : Promise<RecipeResponse|RestResponse> {
    var fetchRecipeJsonRequestUrl = this.getRecipeAPIURLWithPath("/recipe") //baseURL + 'recipe.json'

    var fetchRecipeJsonRequest = new Request(fetchRecipeJsonRequestUrl.toString(), {
      method: 'POST',
      redirect: 'manual',
      credentials: 'include',
      body: recipeText
    });

    const response = await fetch(fetchRecipeJsonRequest)

    var contentType = response.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
      var jsonResponse:RecipeResponse = await response.json();

      if(jsonResponse.success) {
          jsonResponse.responseWrapper.json = JSON.parse(jsonResponse.responseWrapper.json);
      }

      return jsonResponse
    } else {
      return this.unknownFailureRestResponse();
    }
  }

  async deleteRecipe(recipeChronId: number) : Promise<RestResponse> {
    var fetchRecipeJsonRequestUrl = this.getRecipeAPIURLWithPath("/recipe/delete") //baseURL + 'recipe.json'

    var fetchRecipeJsonRequest = new Request(fetchRecipeJsonRequestUrl.toString(), {
      method: 'POST',
      redirect: 'manual',
      credentials: 'include',
      body: String(recipeChronId)
    });

    const response = await fetch(fetchRecipeJsonRequest)

    var contentType = response.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
      var jsonResponse:RestResponse = await response.json();
      return jsonResponse
    } else {
      var failResponse:RestResponse = {success: false, errorCode : "0", message : ""}
      return failResponse;
    }
  }

  async requestRecipeTextToJson(recipeText: string) : Promise<TextToRecipeResponse> {
    var fetchRecipeJsonRequestUrl = this.getRecipeAPIURLWithPath("/recipe/texttojson") //baseURL + 'recipe.json'

    var fetchRecipeJsonRequest = new Request(fetchRecipeJsonRequestUrl.toString(), {
      method: 'POST',
      redirect: 'manual',
      credentials: 'include',
      body: recipeText
    });

    const response = await fetch(fetchRecipeJsonRequest)

    var contentType = response.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
      var jsonResponse:TextToRecipeResponse = await response.json();

      if(jsonResponse.success) {
          jsonResponse.json = JSON.parse(jsonResponse.json);
      }

      return jsonResponse
    } else {
      var failResponse:TextToRecipeResponse = {success: false, errorCode : "0", message : "", json : ""}
      return failResponse;
    }
  }

	async requestAssetFormUploadRequest(sha256:string, byteCount:number, assetContentType:string) : Promise<UploadAssetSignedRequestWrapper> {
		var fetchRecipeJsonRequestUrl = this.getRecipeAPIURLWithPath("/asset/generateUploadURL") //baseURL + 'recipe.json'

		fetchRecipeJsonRequestUrl.searchParams.set('sha256', sha256);
		fetchRecipeJsonRequestUrl.searchParams.set('byteCount', byteCount.toString());
		fetchRecipeJsonRequestUrl.searchParams.set('contentType', assetContentType);

		var fetchRecipeJsonRequest = new Request(fetchRecipeJsonRequestUrl.toString(), {
      method: 'POST',
      redirect: 'manual',
			credentials: 'include'
		});

		const response = await fetch(fetchRecipeJsonRequest)

		var contentType = response.headers.get("content-type");
		if (contentType && contentType.includes("application/json")) {
			var jsonResponse:UploadAssetSignedRequestWrapper = await response.json()
			return jsonResponse
		} else {
      var failResponse:UploadAssetSignedRequestWrapper = {success: false, errorCode : "0", message : "", s3AssetStoreServiceUploadAssetSignedRequest : {formKeys : {}, url: ""}}
      return failResponse;
		}
  }

  async requestOnAssetUpload(onUploadedAssetRequest:OnUploadedAssetRequest) : Promise<AssetRenditionJobGroupResponse|RestResponse> {
		var url = this.getRecipeAPIURLWithPath("/asset/onUploadAsset")

    var requestBody = JSON.stringify(onUploadedAssetRequest);

		var fetchRecipeJsonRequest = new Request(url.toString(), {
      method: 'POST',
      redirect: 'manual',
      headers: this.jsonHeader(),
      credentials: 'include',
      body: requestBody
		});

		const response = await fetch(fetchRecipeJsonRequest)

		var contentType = response.headers.get("content-type");
		if (contentType && contentType.includes("application/json")) {
      var jsonResponse:AssetRenditionJobGroupResponse = await response.json()
      if(this.isAssetRenditionJobGroupResponse(jsonResponse) || this.isRestResponse(jsonResponse)) {
        return jsonResponse;
      }
    }

    return this.unknownFailureRestResponse();
  }

  async requestCreate3DModelOriginalAsset(parentSpecificObjectId:string,parentType:AssetParentType) : Promise<AssetResponseWrapper|RestResponse> {
    var url = this.getRecipeAPIURLWithPath("/asset/create3DModelOriginal")
    
    var createOriginalModelAssetRequest:CreateOriginalModelAssetRequest = 
    {
      parentId: Number(parentSpecificObjectId), 
      parentType: parentType,
    }

    var requestBody = JSON.stringify(createOriginalModelAssetRequest);

		var fetchRecipeJsonRequest = new Request(url.toString(), {
      method: 'POST',
      redirect: 'manual',
      headers: this.jsonHeader(),
      credentials: 'include',
      body: requestBody
		});

		const response = await fetch(fetchRecipeJsonRequest)

		var contentType = response.headers.get("content-type");
		if (contentType && contentType.includes("application/json")) {
      var jsonResponse:AssetResponse = await response.json()
      if(this.isAssetResponse(jsonResponse) || this.isRestResponse(jsonResponse)) {
        return jsonResponse.responseWrapper;
      }
    }

    return this.unknownFailureRestResponse();
  }

  async requestAssetUploadJobGroupStatus(jobId:string) : Promise<AssetRenditionJobGroupResponse|RestResponse> {
		var url = this.getRecipeAPIURLWithPath("/job/assetgroup/"+jobId)

		var fetchRecipeJsonRequest = new Request(url.toString(), {
      method: 'GET',
      redirect: 'manual',
      headers: this.jsonHeader(),
      credentials: 'include',
		});

		const response = await fetch(fetchRecipeJsonRequest)

		var contentType = response.headers.get("content-type");
		if (contentType && contentType.includes("application/json")) {
      var jsonResponse:AssetRenditionJobGroupResponse = await response.json()
      if(this.isAssetRenditionJobGroupResponse(jsonResponse) || this.isRestResponse(jsonResponse)) {
        return jsonResponse;
      }
    }

    return this.unknownFailureRestResponse();
  }

  async requestAssetById(assetId:string) : Promise<AssetResponse|RestResponse> {
		var url = this.getRecipeAPIURLWithPath("/asset/"+assetId)

		var fetchRecipeJsonRequest = new Request(url.toString(), {
      method: 'GET',
      redirect: 'manual',
      headers: this.jsonHeader(),
      credentials: 'include',
		});

		const response = await fetch(fetchRecipeJsonRequest)

		var contentType = response.headers.get("content-type");
		if (contentType && contentType.includes("application/json")) {
      var jsonResponse:AssetResponse = await response.json()
      if(this.isAssetResponse(jsonResponse) || this.isRestResponse(jsonResponse)) {
        return jsonResponse;
      }
    }

    return this.unknownFailureRestResponse();
  }

  async requestAssetByHashTypeAndHash(assetHashType:HashType, hash:string) : Promise<AssetResponse|RestResponse> {
		var url = this.getRecipeAPIURLWithPath("/asset/"+assetHashType+"/"+hash)

		var fetchRecipeJsonRequest = new Request(url.toString(), {
      method: 'GET',
      redirect: 'manual',
      headers: this.jsonHeader(),
      credentials: 'include',
		});

		const response = await fetch(fetchRecipeJsonRequest)

		var contentType = response.headers.get("content-type");
		if (contentType && contentType.includes("application/json")) {
      var jsonResponse:AssetResponse = await response.json()
      if(this.isAssetResponse(jsonResponse) || this.isRestResponse(jsonResponse)) {
        return jsonResponse;
      }
    }

    return this.unknownFailureRestResponse();
  }

  getURLParam(param:string) {
    var href = window.location.href

    var url = new URL(href);
    return url.searchParams.get(param);
  }

  isLocalEnv() : boolean {
    return new Environment().isLocalEnv();
  }

  isProdEnv() : boolean {
    return new Environment().isProdEnv();
  }

  getRecipeAPIURLWithPath(pathParams:string | null) : URL {
    
    var url;

    var isLocal = this.isLocalEnv();

    if(isLocal) {
      // 8001 or 35729 for live reload
      const currentHost = window.location.hostname
      url = new URL("http://" + currentHost + ":8001" + pathParams);
    } else {
      url = new URL("https://api.lenyceats.com" + pathParams);
    }

    return url
  }

  unknownFailureRestResponse() : RestResponse  {
    var unknownFailureRestResponse:RestResponse = {success: false, errorCode : "0", message : ""};
    return unknownFailureRestResponse;
  }

  isMultiRecipeResponse(object: any): object is MultiRecipeResponse {
    return object != null && 'recipeResponses' in object;
  }

  isRecipeResponse(object: any): object is RecipeResponse {
    return object != null && 'responseWrapper' in object && object.responseWrapper != null && 'json' in object.responseWrapper;
  }

  isAssetRenditionJobGroupResponse(object: any): object is AssetRenditionJobGroupResponse {
    return object != null && 'responseWrapper' in object && object.responseWrapper != null && 'parentId' in object.responseWrapper;
  }

  isAssetResponse(object: any): object is AssetResponse {
    return object != null && 'responseWrapper' in object && object.responseWrapper != null && 'assetType' in object.responseWrapper;
  }

  isAssetResponseWrapper(object: any): object is AssetResponseWrapper {
    return object != null && 'assetType' in object;
  }

  isRestResponse(object: any): object is RestResponse {
    return object != null && 'success' in object;
  }

  appendStringMapToStringMap(a:StringMap, b:StringMap) : StringMap {
    var combined:StringMap = {}

    let key
    for (key in a) {
     if(a.hasOwnProperty(key)){
        combined[key] = a[key];
      }
    }
    for (key in b) {
      if(b.hasOwnProperty(key)){
        combined[key] = b[key];
      }
    }

    return combined;
  }
}

export default RecipeRequests;
