export default class Environment {
    isLocalEnv() : boolean {
        const currentHost = window.location.hostname

        const isLocal = currentHost === "localhost" || currentHost.startsWith("192.168.2.") || currentHost.endsWith(".local")
        || currentHost === "127.0.0.1" || currentHost === "";

        return isLocal;
    }
    
    isProdEnv() : boolean {
        return !this.isLocalEnv() && window.location.host.endsWith(".lenyceats.com")
    }
}