import React, {Suspense, lazy} from 'react';
import { hydrate, render } from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import './index.css';

import Home from './components/home';
import Analytics from './analytics/analytics.js'
import ErrorBoundary from './components/errorBoundary';

const NoRoute = React.lazy(() => import('./components/404'));
const RecipeViewer = React.lazy(() => import('./components/recipeViewer'));


// https://reactjs.org/docs/code-splitting.html
// code split logged in views, including ones with all the 3rd party ace code 
const Login = React.lazy(() => import('./components/login'));
const PostViewer = React.lazy(() => import('./components/postViewer'));
const MyRecipes = React.lazy(() => import('./components/myRecipes'));
const CreateOrUpdateRecipe = React.lazy(() => import('./components/createOrUpdateRecipe'));
const NavBar = React.lazy(() => import('./components/navbar'));

// https://medium.com/@pshrmn/a-simple-react-router-v4-tutorial-7f23ff27adf

const myErrorHandler = (error, info) => {
	// Do something with the error
	// E.g. log to an error logging client here
	console.log(error)
	console.log(info)
  }
  

const routes = [
	{
		path: "/login",
		exact: true,
		navbar: () => <NavBar showMenu={true} />,
	},
	{
		path: "/createOrUpdateRecipe",
		exact: true,
		navbar: () => <NavBar showMenu={true} />,
	},
	{
		path: "/myrecipes",
		exact: true,
		navbar: () => <NavBar showMenu={true} />,
	},
  ];

  /*
  const Main = () => (
	<main>
	  <BrowserRouter>
		  <Suspense fallback={<div id="homeLoadingDiv">Loading...</div>}>
			  <Switch>
				{routes.map((route, index) => (
				<Route
					key={index}
					path={route.path}
					exact={route.exact}
					children={<route.main />}
				/>
				))}
			  </Switch>
		  </Suspense>
	  </BrowserRouter>
	</main>
	*/

	// v6 https://medium.com/@manishsundriyal/whats-new-in-react-router-v6-20eefe665be9
	// https://reactrouter.com/docs/en/v6/getting-started/overview
	// https://reactrouter.com/docs/en/v6/api
const Main = () => (
	<ErrorBoundary>
		<main>
			<BrowserRouter>
				<Suspense fallback={<div id="homeLoadingDiv"></div>}>
					<Switch>
						{routes.map((route, index) => (
						<Route
							key={index}
							path={route.path}
							exact={route.exact}
							children={<route.navbar />}
						/>
						))}
					</Switch>
					<Switch>
						<Route exact path='/' component={Home}/>
						<Route exact path='/recipe/:recipeId([0-9]+)' component={RecipeViewer}/>
						<Route exact path='/post/:postId([0-9]+)' component={PostViewer}/>
						<Route exact path='/login' component={Login}/>
						<Route exact path='/createOrUpdateRecipe' component={CreateOrUpdateRecipe}/>
						<Route exact path='/myrecipes' component={MyRecipes} />
						<Route exact path='/404' component={NoRoute} />
						<Route component={NoRoute} />
					</Switch>
				</Suspense>
		</BrowserRouter>
	</main>
  </ErrorBoundary>
);

/*
<Switch>
	<Route exact path='/recipe/*' component={RecipeViewer}/>
	<Route exact path='/post/*' component={PostViewer}/>
	<Route exact path='/' component={Home}/>
	<Route exact path='/login'/>
	<Route exact path='/createOrUpdateRecipe' component={CreateOrUpdateRecipe}/>
	<Route exact path='/myrecipes' component={MyRecipes} />
</Switch>

*/

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  hydrate(<Main />, rootElement);
} else {
  render(<Main />, rootElement);
}

const analytics = new Analytics();
analytics.startAnalytics();