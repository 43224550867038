import React from 'react';
import {Paper, Tabs, Tab, AppBar, Toolbar, Button, Menu, MenuItem, Box} from '@mui/material'
import SearchBar from  "./searchBar"
import svgLogo from './logo.svg'
import { useHistory } from "react-router-dom";
import { createTheme, ThemeProvider} from '@mui/material/styles';
import {makeStyles} from '@mui/styles'

const useStyles = makeStyles({
    appbar : {
        backgroundColor:'transparent',
        marginBottom: 16,
        maxHeight:200
        
    },
    toolbar : {
        display:'flex',
        flexDirection:'column'
    },
    menuButton : {
        fontSize: 'large'
    },
    logoandsearchcontainer : {
        maxWidth:640,
        paddingBottom:2
    },
    searchbar: {
    },
    logoicon : {
        maxHeight:100,
        objectFit:'contain',
        width:'100%'
    },
});

export default function NavBar(props) {    
    const classes = useStyles();
    let history = useHistory();
    const [mobileMenuState, setMobileMenuState] = React.useState(null);

    var showMenu = false;
    if(props != null && props.showMenu !== null && props.showMenu == true) {
        showMenu = true;
    }
    var showSearchBar = false;
    if(props != null && props.showSearchBar !== null && props.showSearchBar == true) {
        showSearchBar = true;
    }

    var menu = undefined;
    var searchBar = undefined;

    const handleHomeTapped = event => {
        setMobileMenuState(null);
    
        // no history, we want ssr
        //history.push("/")
        window.location="/"
    };

    if(showMenu) {

        // menu
        const handleMobileMenuOpened = event => {
            setMobileMenuState(event.currentTarget);
        };
        
        const handleMobileMenuClose = () => {
            setMobileMenuState(null);
        };
        
        const handleMobileMenuTapped = event => {
            setMobileMenuState(null);
        
            history.push("/" + event.currentTarget.dataset.value)
        };

        menu = []
        menu[0] = <Button key="menu_button" size="large" className={classes.menuButton} aria-controls="mobile-menu" aria-haspopup="true" onClick={handleMobileMenuOpened}>
                👤 Menu
            </Button>

        menu[1] =   <Menu key="mobile-menu"
            id="mobile-menu"
            anchorEl={mobileMenuState}
            keepMounted
            open={Boolean(mobileMenuState)}
            onClose={handleMobileMenuClose}
            className={classes.menu}
            >
            <MenuItem data-value="myrecipes" onClick={handleMobileMenuTapped}>My Recipes</MenuItem>
            <MenuItem data-value="createOrUpdateRecipe" onClick={handleMobileMenuTapped}>New Recipe</MenuItem>
            </Menu>

    }

    if(showSearchBar) {
        const handleRecipeSearchChronIdSelected = recipeChronId => {
            console.log("pushing " + recipeChronId)
            if(recipeChronId != null) {
                window.location="/recipe/" + recipeChronId
                // for now we want a real link, not history.push because we're using ssr
                // history.push will not do ssr
                //history.push("/recipe/" + recipeChronId)
            }
        };

        searchBar = <SearchBar className={classes.searchbar} onRecipeSearchChronIdSelected={handleRecipeSearchChronIdSelected}/>
    }
        
    return (
        // https://material-ui.com/api/app-bar/
        // homeLogo id required for ssr check
        <AppBar position="static" className={classes.appbar} color='transparent'>

            <Toolbar className={classes.toolbar}>

                <span className={classes.logoandsearchcontainer}>
                    <img id="homeLogo" className={classes.logoicon} src={svgLogo} style={{cursor:'pointer'}} onClick={handleHomeTapped} />
                    {searchBar}                    
                </span>    
                {menu}            
            </Toolbar>

        </AppBar>

    );

}

/*
        <div>
        <div id="homeLogo" className="Home-logo-container">
            <img className="Home-logo-image" src={require('./logo.svg')}/>	
        </div>
        <Paper>
            <Tabs
            value={tabvalue}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            centered
            >
            <Tab value="myrecipes" label="Home" />
            <Tab value="myrecipes" label="Recipes" />
            <Tab value="createOrUpdateRecipe" label="New Recipe" />
            </Tabs>
        </Paper>
        </div>

        */