import { GLTFLoader, GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import { GLTFExporter,GLTFExporterOptions } from 'three/examples/jsm/exporters/GLTFExporter';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import FileUtil from "./fileUtil";
import { Scene, Object3D, Vector3 } from 'three';
import { FileHash } from './fileUtil';
import SceneUtil from './sceneUtil';
import { AssetResponseWrapper, AssetFormat } from '../network/responses/recipe-crud-webapp-dto';
import Environment from './environment';

export default class ModelIOUtil {
    
  constructor() {
  
  }

  async loadAndParseUrl(modelUrl:string) : Promise<{"gltf" : GLTF|null, "error" : any}> {
    // npm part https://webpack.js.org/guides/getting-started/
    var loader = new GLTFLoader();
      
    // for now using recommendation from https://github.com/google/draco
    // https://www.lenyceats.com/draco/
    var dracoLoader = new DRACOLoader(); 
    //dracoLoader.setDecoderPath( '/draco/');
    //dracoLoader.setDecoderPath( 'https://www.gstatic.com/draco/v1/decoders/');
    // new recommendation from https://github.com/google/draco is to use versioned decoder 
    dracoLoader.setDecoderPath( 'https://www.gstatic.com/draco/versioned/decoders/1.4.3/' );
    loader.setDRACOLoader( dracoLoader );

    return await this.loadAndParseModelURLAsync(loader, modelUrl);
  }

  async parseFile(modelFile:File) : Promise<{"gltf" : GLTF|null, "error" : any}> {
    var reader = new FileReader();

    let contentBuffer = await new FileUtil().readFileAsync(modelFile, reader);

    if(contentBuffer instanceof ArrayBuffer) {
      // npm part https://webpack.js.org/guides/getting-started/
      var loader = new GLTFLoader();
        
      //dracoLoader.setDecoderPath( '/draco');
      // for now using recommendation from https://github.com/google/draco
      var dracoLoader = new DRACOLoader(); 
      //dracoLoader.setDecoderPath( '/examples/js/libs/draco/' );
      //dracoLoader.setDecoderPath( 'https://www.gstatic.com/draco/v1/decoders/');
      // new recommendation from https://github.com/google/draco is to use versioned decoder 
      dracoLoader.setDecoderPath( 'https://www.gstatic.com/draco/versioned/decoders/1.4.3/' );
      loader.setDRACOLoader( dracoLoader );

      return await this.parseModelFileAsync(loader, contentBuffer);
    }

    return {"gltf":null, "error":null};
  }

  async tranform(modelFile:File, transform:THREE.Vector3, quaternion:THREE.Quaternion, boundingBoxCm:number|null) : Promise<{"file" : File|null, "error" : any}> {
    var {gltf,error} = await this.parseFile(modelFile);

    if(gltf != null) {
      var mesh0 = new SceneUtil().findFirstMesh(gltf.scene);      

      if(mesh0 != null) {
        mesh0.geometry.computeBoundingBox(); // Geometry https://threejs.org/docs/#api/en/core/Geometry
        mesh0.geometry.center();

        if(boundingBoxCm != null && boundingBoxCm > 0) {
          // scale in relation to meters
          mesh0.scale.copy(new Vector3(boundingBoxCm*.01,boundingBoxCm*.01,boundingBoxCm*.01))
        }

        mesh0.quaternion.copy(quaternion);
        //mesh0.geometry.translate(transform.x, transform.y, transform.z);
        mesh0.position.copy(transform);

        var scene = new Scene();
        scene.add(mesh0);

        /*
        gltf.animations; // Array<THREE.AnimationClip>
        gltf.scene; // THREE.Group
        gltf.scenes; // Array<THREE.Group>
        gltf.cameras; // Array<THREE.Camera>
        gltf.asset; // Object
        */
        // Note just exporting scene here, could do model instead
        const {file,error} = await this.exportGLTFBinaryAsync(scene);

        if(file instanceof File) {
          return {"file":file, "error":error};
        }
        else {
          return {"file":null, "error":error};
        }
      }
      else {
        return {"file":null, "error":null};
      }
    }
    else {
      return {"file":null, "error":null};
    }
  }

  async loadAndParseModelURLAsync(loader:GLTFLoader, modelUrl:string) : Promise<{"gltf" : GLTF|null, "error" : any}> {
    return new Promise((resolve, reject) => {
      loader.load( modelUrl, function ( gltf ) {
        resolve({"gltf":gltf, "error":null});
      },
      function ( xhr ) {
    
        /*
        if ( xhr.lengthComputable ) {
          var percentComplete = xhr.loaded / xhr.total * 100;
          console.log( Math.round(percentComplete, 2) + '% downloaded' );
        }
        */

      }, function ( error ) {
        resolve({"gltf":null, "error":error});
      } );   
    });
  }

  async parseModelFileAsync(loader:GLTFLoader, contentBuffer:ArrayBuffer) : Promise<{"gltf" : GLTF|null, "error" : any}> {
    return new Promise((resolve, reject) => {
      // https://github.com/mrdoob/three.js/blob/dev/editor/js/Loader.js#L655
      loader.parse( contentBuffer, '', function ( gltf ) {
        resolve({"gltf":gltf, "error":null});
        
      }, function( error ) {
        resolve({"gltf":null, "error":error});
      });      
    });
  }

  // // https://threejs.org/docs/#examples/en/exporters/GLTFExporter
  async exportGLTFBinaryAsync(scene:Object3D) : Promise<{"file" : object|null, "error" : any}> {
    var exporter = new GLTFExporter();
    var exportOptions:GLTFExporterOptions = {
      binary: true,
    }

    return new Promise((resolve, reject) => {
      exporter.parse( scene, function ( gltfArrayBuffer ) {

        var randomForFilename = Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
        var filename =  "model_" + randomForFilename + ".glb";
        /*
        
        const blob = new Blob(gltfArrayBuffer, { type: 'modle/gltf+binary' } ), filename );
        
        var fileUrl = new File(URL.createObjectURL( blob ));
        */

        if(gltfArrayBuffer instanceof ArrayBuffer) {
          const blobAsFile = new File([gltfArrayBuffer], filename, {type: 'model/gltf-binary'});
          resolve({"file":blobAsFile, "error":null}); 
        }
        else {
          resolve({"file":null, "error":null}); 
        }
               
      }, exportOptions);
    });
  }

  originalAssetNameToModelRendition(originalAssetNameOrHash:string, assets:AssetResponseWrapper[], targetPolyCount:number, targetAssetFormat:AssetFormat) : AssetResponseWrapper|null {
    var renditionAsset = null;
  
    var originalAssetHash = originalAssetNameOrHash;
    if(originalAssetNameOrHash.indexOf('.') > -1) {
      originalAssetHash = originalAssetNameOrHash.split('.').slice(0, -1).join('.')
    }

    if(assets != null) {
      var diffFromTarget = 9999999999;
  
      assets.forEach(function(nextAsset, index, array) {
  
        if(nextAsset.hash === originalAssetHash) {
          nextAsset.renditions.forEach(function(nextRendition, index1, array1) {
            
            if(!nextRendition.rendition) {
  
              // since usdz doesn't currently have counts, get for the original glbs
              var polygonCount = nextRendition.polygonCount;
  
              nextRendition.renditions.forEach(function(nextRendition2, index2, array2) {
  
                if(nextRendition2.rendition && nextRendition2.assetFormat == targetAssetFormat) {
  
                  
                  var diff = Math.abs(targetPolyCount-polygonCount);
                  if(diff < diffFromTarget) {
                    diffFromTarget = diff;
                    renditionAsset = nextRendition2;
                  }
                }
  
              });
            }
  
          });
        }
      });
    }
  
    return renditionAsset;
  }

  originalAssetNameToAsset(originalAssetNameOrHash:string, assets:AssetResponseWrapper[], findRendtion:boolean=false) {
    var outputAsset = null;
  
    var originalAssetHash = originalAssetNameOrHash;
    if(originalAssetNameOrHash.indexOf('.') > -1) {
      originalAssetHash = originalAssetNameOrHash.split('.').slice(0, -1).join('.')
    }
    
    if(assets != null) {
      assets.forEach(function(nextAsset, index, array) {
  
        if(nextAsset.hash === originalAssetHash) {
          if(findRendtion) {
            nextAsset.renditions.forEach(function(nextRendition, index1, array1) {
              outputAsset = nextRendition;
            });
          }
          else {
            outputAsset = nextAsset;
          }
        }
      });
    }

    return outputAsset;
  }
}