import ReactGA from 'react-ga';
import Environment from '../utility/environment'

class Analytics {

	constructor() {
		var isDebugMode = new Environment().isLocalEnv();
		
		this.shouldInitGA = !isDebugMode
	}

	startAnalytics() {
		if(this.shouldInitGA) {
			ReactGA.initialize('UA-121432314-1');
			var pageName = window.location.pathname + window.location.search;
			ReactGA.pageview(pageName);
		}
	}
}

export default Analytics;
