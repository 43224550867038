import React, { Component } from 'react';
import './home.css';
import {Helmet} from 'react-helmet';
import RecipeRequests from '../network/RecipeRequests';
import { RecipeResponseWrapper,AssetResponseWrapper, PostResponseWrapper } from '../network/responses/recipe-crud-webapp-dto';
import RecipeListItem from './recipelistitem'
import NavBar from './navbar';

declare global {
  interface Window { snapStore: any; }
}

export interface Props {
  classes:any
}

function assetToRendition(asset:AssetResponseWrapper) : AssetResponseWrapper | null {
  var renditionAsset:AssetResponseWrapper|null = null;

  if(asset != null) {
    asset.renditions.forEach(function(nextRendition, index1, array1) {
      renditionAsset = nextRendition;
    });
  }

  return renditionAsset;
}

function originalAssetNameToRendition(originalAssetName:string, assets:AssetResponseWrapper[]) : AssetResponseWrapper | null {
  var renditionAsset = null;

  let originalAssetHash = originalAssetName.split('.').slice(0, -1).join('.')
  
  assets.forEach(function(nextAsset, index, array) {

    if(nextAsset.hash === originalAssetHash) {
      nextAsset.renditions.forEach(function(nextRendition, index1, array1) {
        renditionAsset = nextRendition;
      });
    }
  });

  return renditionAsset;
}

class HomeRecipeList extends Component <HomeRecipeListProps,HomeRecipeListModel> {


  constructor(props:HomeRecipeListProps) {
    super(props);

    this.onViewRecipe = this.onViewRecipe.bind(this);

  }

	render() {
		var sectionText = this.props.text;
    var recipes = this.props.recipes;

    var recipeList:JSX.Element[] = [];
    /*
      var recipeChronId = this.props.recipe.chronicleId;
      var link = "/recipe/" + recipe.chronicleId
    */

    if(recipes != null && recipes.length > 0) {

      var recipeIndex=0;
      var nextRecipe:RecipeResponseWrapper
      // map loses order
      for (recipeIndex = 0; recipeIndex < recipes.length; recipeIndex++) {
        let nextRecipe = recipes[recipeIndex];
        recipeList.push(<RecipeListItem key={recipeIndex} recipe={nextRecipe} canEdit={false} editRecipeHander={null} deleteRecipeHander={null} viewRecipeHander={this.onViewRecipe}/>)
      };
    }

    return (
    	<div id="recipeList" className="HomeRecipeList-container">
    		<div className="HomeRecipeList-heading">{sectionText}</div>
    		{recipeList}
    	</div>
    );
  }
  
  onViewRecipe(recipeResponseWrapper:RecipeResponseWrapper) {
    
  }
}

interface HomeRecipeListProps {
  text:string
  recipes:RecipeResponseWrapper[]
}

interface HomeRecipeListModel {

}

interface HomePostListItemProps {
  post:PostResponseWrapper
}

interface HomePostListItemModel {
}

class HomePostListItem extends Component <HomePostListItemProps,HomePostListItemModel> {	
  render() {
    var post = this.props.post;
    var assets = post.assets;

    var name = post.objectName;

    var coverImageUrl = null;
    if(assets != null && assets.length > 0) {
      var postAsset0Rendtion = assetToRendition(assets[0]); // TODO return in response
      if(postAsset0Rendtion != null) {
        coverImageUrl = postAsset0Rendtion.url;
      }
    }
    var link = "/post/" + post.chronicleId;

    var imageStyle = {}

    if(coverImageUrl && coverImageUrl.length > 0) {
      imageStyle = {
          backgroundImage: "url('" + coverImageUrl + "')"
      };
    }

  return (
    <div className="HomeRecipeListItem-container">
      <a href={link} className="HomeRecipeListItem-picture" style={imageStyle}>
        <h1>{name}</h1>
      </a>
    </div>
    );
  }
}

interface HomePostListProps {
  text:string
  posts:PostResponseWrapper[]
}

interface HomePostListModel {
  
}

class HomePostList extends Component <HomePostListProps,HomePostListModel> {	
  
  render() {
    var sectionText = this.props.text;
    var posts = this.props.posts;

    var postList;

    if(posts != null && posts.length > 0) {

      postList = posts.map((nextPost, count) => {
        return (
          <HomePostListItem key={count} post={nextPost} />
          );
      });

      return (
        <div className="HomeRecipeList-container">
          <div className="HomeRecipeList-heading">{sectionText}</div>
          {postList}
        </div>
      );
    }
    else {
      return (
        <span className="HomeRecipeList-container"/>
      );
    }


  }
}

interface HomeProps {
}

interface HomeModel {
  recipes:RecipeResponseWrapper[];
  posts:PostResponseWrapper[];

  refreshModelFromNetwork:boolean
}

class Home extends Component <HomeProps,HomeModel> {
  
  constructor(props:HomeProps) {
    super(props);

    // todo handle init with prop of recipes

    var snapshotKey = "/recipe/public/list";
    var snapshot = window.snapStore && window.snapStore[snapshotKey];

    if(snapshot) {
      this.onRecipeResponse(snapshot);
    }
    else {
      this.state = {
        "recipes": [],
        "posts": [],
        refreshModelFromNetwork : true,
      };
    }
  }

  render() {

  	if(this.state === null || this.state.refreshModelFromNetwork) {
  		return (
      		<div></div>
    	);
  	}
  	else {

	  	var recipes = this.state.recipes;
      var posts = this.state.posts;
      
	    return (
	      <div>
          <Helmet>
            <meta property="og:title" content="Le NYC Eats" />
            <title>Le NYC Eats</title>
          </Helmet>
          <NavBar showSearchBar={true}/>
	        <HomePostList text="Latest Posts" posts={posts} />
          <HomeRecipeList text="Latest Recipes" recipes={recipes} />
	      </div>
	     );
	   }
  }

  updateStateWithModels(recipes:RecipeResponseWrapper[], posts:PostResponseWrapper[]) {

    if(this.state == null) {
      this.state = { "recipes": recipes,
      "posts": posts,
      refreshModelFromNetwork:false
      }
    }
    else {
      this.setState({
        "recipes": recipes,
        "posts": posts,
        refreshModelFromNetwork:false
      });
    }


  }

    /**
   * React 16 warning: Converted componentWillMount to init (read snapshot) and componentDidMount (make network request)
   * https://reactjs.org/docs/hooks-effect.html
   */
  /*
Warning: componentWillMount has been renamed, and is not recommended for use. See https://fb.me/react-unsafe-component-lifecycles for details.

* Move code with side effects to componentDidMount, and set initial state in the constructor.
* Rename componentWillMount to UNSAFE_componentWillMount to suppress this warning in non-strict mode. In React 17.x, only the UNSAFE_ name will work. To rename all deprecated lifecycles to their new names, you can run `npx react-codemod rename-unsafe-lifecycles` in your project source folder.

Please update the following components: SideEffect(NullComponent)
*/
  componentDidMount() {
    if(this.state != null) {

      if(this.state.refreshModelFromNetwork) {
        this.fetchRecipes()

        // todo posts
      }
    }
  }

  async fetchRecipes() {
    var recipeRequests = new RecipeRequests()
    var multiRecipeResponse = await recipeRequests.requestAllPublicLatestRecipes()
    this.onRecipeResponse(multiRecipeResponse)
  }

  onRecipeResponse(multiRecipeResponse:any) {

    var recipeRequests = new RecipeRequests()
    var objectConstructor = ({}).constructor; // make json an object again when it comes from a snapshot

    if(recipeRequests.isMultiRecipeResponse(multiRecipeResponse)) {
      var recipeResponseWrappers:RecipeResponseWrapper[] = []
      multiRecipeResponse.recipeResponses.forEach(function(nextRecipe, index1, array1) {

        if(nextRecipe.responseWrapper.json.constructor !== objectConstructor) {
          nextRecipe.responseWrapper.json = JSON.parse(nextRecipe.responseWrapper.json);
        }

        recipeResponseWrappers.push(nextRecipe.responseWrapper)
      });

      this.updateStateWithModels(recipeResponseWrappers, [])
    }
  }
}

export default Home;