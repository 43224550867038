import React, { useEffect, ChangeEvent, useRef } from 'react';
import { Typography,Container,TextField,useMediaQuery } from '@mui/material';
import NavBar from './navbar';
import Autocomplete from '@mui/material/Autocomplete';
import {AutocompleteChangeReason,AutocompleteChangeDetails,createFilterOptions} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import SearchIcon from '@mui/icons-material/Search';

//https://material-ui.com/system/spacing/
import { spacing } from '@mui/system';

//https://material-ui.com/customization/color/#color
import yellow from '@mui/material/colors/yellow';
import RecipeRequests from '../network/RecipeRequests';

interface Props {
  className?:string;
  width:number;
  onRecipeSearchChronIdSelected:any;
}

interface SearchItem {
  name:string;
  chronId:string;
}

// https://material-ui.com/components/autocomplete/
export default function SearchBar(props:Props) {

    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState<SearchItem[]>([]);
    const loading = open && options.length === 0;

    const [autocompleteInputValue, setAutoCompleteInputValue] = React.useState('');

    const handleRecipeChanged = (event: ChangeEvent<{}>, value: SearchItem | null, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<SearchItem> | undefined) => {
      
      if(value != null && reason === "selectOption") {
        props.onRecipeSearchChronIdSelected(value.chronId);
      }
    };    
  
    React.useEffect(() => {
      let active = true;
  
      if (!loading) {
        return undefined;
      }
  
      (async () => {
        var recipeRequests = new RecipeRequests();
        const response = await recipeRequests.requestRecipesForSearch();
  
        var searchItems:SearchItem[] = []
        if(recipeRequests.isMultiRecipeResponse(response)) {
          response.recipeResponses.forEach(nextWrapper => {
            const nextRecipeWrapper = nextWrapper.responseWrapper;
            searchItems.push( {name: nextRecipeWrapper.objectName, chronId:String(nextRecipeWrapper.chronicleId)} as SearchItem )
          });
        }
        if (active) {
          setOptions(searchItems);
        }
      })();
  
      return () => {
        active = false;
      };
    }, [loading]);
  
    React.useEffect(() => {
      if (!open) {
        setOptions([]);
      }
    }, [open]);

    
    var isMobileScreenSize = useMediaQuery('(max-width: 420px)'); 

    const filterOptions = createFilterOptions({
      limit: isMobileScreenSize ? 3 : 10,
      stringify: (option: SearchItem) => option.name,
    });
    
    // https://material-ui.com/components/autocomplete/
    return (
      <Autocomplete
        className={props.className}
        filterOptions={filterOptions}
        open={open && autocompleteInputValue.length > 0}
        onOpen={() => {          
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        value={undefined}
        inputValue={autocompleteInputValue}
        onInputChange={(event, newInputValue) => {
          setAutoCompleteInputValue(newInputValue);
          if(newInputValue.length === 0) {
            setOpen(false);
          }
        }}
        autoHighlight={false}
        popupIcon={null}
        noOptionsText = {'No results'}
        disableClearable
//        getOptionSelected={(option, value) => option.name === value.name}
        getOptionLabel={(option) => option.name}
        onChange={handleRecipeChanged}
        options={options}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <React.Fragment>
                   <SearchIcon />
                </React.Fragment>
              ),
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    );
  }

  
