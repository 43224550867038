import React, { useEffect, useRef } from 'react';
import ModelIOUtil from '../utility/modelIOUtil';
import { AssetResponseWrapper } from '../network/responses/recipe-crud-webapp-dto';
import ThreeDModelViewer from './threeDModelViewer'
import './recipelistitem.css';

export interface Props {
  coverModel:any;
  assets:AssetResponseWrapper[];
  targetPolygons:number;
  interactive?:boolean;
  className?:string;
}

export default function CoverModelThreeDModelViewer(props:Props) {

  var modelIOUtil = new ModelIOUtil();

  var coverModel = props.coverModel;
  var assets = props.assets;
  var targetPolygons = props.targetPolygons;

  var coverModelOriginalAsset = modelIOUtil.originalAssetNameToAsset(coverModel.modelUrl, assets, false);
  var coverModelAsset = modelIOUtil.originalAssetNameToModelRendition(coverModel.modelUrl, assets, targetPolygons, "GLB");

  if(coverModelOriginalAsset == null || coverModelAsset == null) {
    return (
      <div/>
    )
  }

  var modelRenderOptions = {
    fov:17,
    toneMappingExposure:1.25,
    fitToCameraOffset:5,
    roateAngleDiv: 2.9,
    linearTextureEncoding:false
    }

    if(coverModel.fov != null) {
      modelRenderOptions.fov = coverModel.fov;
    }
    if(coverModel.toneMappingExposure != null) {
      modelRenderOptions.toneMappingExposure = coverModel.toneMappingExposure;
    }
    if(coverModel.fitToCameraOffset != null) {
      modelRenderOptions.fitToCameraOffset = coverModel.fitToCameraOffset;
    }
    if(coverModel.roateAngleDiv != null) {
      modelRenderOptions.roateAngleDiv = coverModel.roateAngleDiv;
    }
    if(coverModel.linearTextureEncoding != null) {
      modelRenderOptions.linearTextureEncoding = coverModel.linearTextureEncoding;
    }

  return (
    <ThreeDModelViewer className={props.className} key={coverModelAsset.hash+"_3dviewer"} interactive={props.interactive} modelUrl={coverModelAsset.url} cameraForMeshModel={modelRenderOptions} disableZoom={true} />
  );
}



  